import PaymentTable from 'components/admin/PaymentTable';
import React from 'react';

const PaymentManagement = () => {
    return (
        <>
            <PaymentTable />
        </>
    )
}

export default PaymentManagement