import React from 'react';
import UserTable from 'components/admin/UserTable';

const UserManagement = () => {
    return (
        <>
            <UserTable />
        </>
    )
}

export default UserManagement