import OrderTable from 'components/admin/OrderTable';
import React from 'react';

const OrderManagement = () => {
    return (
        <>
            <OrderTable />
        </>
    )
}

export default OrderManagement